<template>
<div class="Article">
  <v--hard></v--hard>
<!--<button v-on:click="test"></button>-->
<!--  <v&#45;&#45;hard v-if="PAN"></v&#45;&#45;hard>-->
<!--  <v&#45;&#45;about v-else></v&#45;&#45;about>-->
<!--  <div class="aaa">-->
<!--    <router-link to="/about">11111</router-link>-->
<!--    <router-view></router-view>-->
<!--  </div>-->
<!--<v&#45;&#45;article-text  v-model="formContent"></v&#45;&#45;article-text>-->
  <div style="width: 80%;margin: 0 auto">
       <div style="width: 60%">
            <el-card class="box-card">
                <span class="BTi">{{article.articleTitle}}</span>
                <div style="height: 20px;margin-top: 20px">
                  <span>{{article.articleAbstract}}</span>
                </div>
                <div style="height: 20px;margin-top: 20px">
                  <i class="el-icon-user-solid" style="margin-left: 20px"><span style="margin-left: 10px">{{article.articleAuthor}}</span></i>
                  <i class="el-icon-view" style="margin-left: 40px"><span style="margin-left: 10px">{{article.articleViewCount}}</span></i>
                  <i class="el-icon-chat-dot-round" style="margin-left: 40px"><span style="margin-left: 10px">{{article.articleCategory}}</span></i>
                  <i class="el-icon-time" style="margin-left: 40px"><span style="margin-left: 10px">{{article.articleTime}}</span></i>
                </div>
<!--              <div style="background-color: aqua;height: 30px;padding-top: 10px;margin-top: 20px">版权 本文为蘑菇博客原创文章，转载无需和我联系，但请注明来自蘑菇博客 http://www.moguit.cn</div>-->
                 <div  class="text item">
                   <p v-html="article.articleText"></p>

                </div>
            </el-card>
       </div>
  </div>
</div>
</template>


<script>
import Hard from "@/components/Hard";
import About from "@/components/About";
export default {

  name: "Article",
  components: {
    'v-Hard':Hard,
    'v-About':About
  },
  data(){
    return{
      article:[],
      PAN:'true',
      formContent:""
    }
  },
  mounted() {
    console.log(this.$route.query.id)
  },
  methods:{
    test:function(){
      alert(this.formContent)
    }


  },
  created() {
   let id=this.$route.query.id
    const _this = this
    this.$axios.get('https://www.realmmy.com/api/show/article?id='+id).then(function (resp) {
      _this.article = resp.data
    })
  }


}
</script>

<style scoped>

</style>
