<template>
  <div class="about">
    <h1>123456789</h1>
  </div>
</template>
<script>
export default {
  name: "about",
  data(){
    return {
      msg:"123"
    }

  }
}

</script>